import { useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import VerticalStoriesCSS from './VerticalStories.module.css';
import UserStories from '../UserStories/UserStories';
import { useGetUsersQuery } from '../../features/user/usersApiSlice';
import useAuth from '../../hooks/useAuth';
import Ad from './Ad';
import { useGetAdvertisersQuery } from '../../features/advertiser/advertiserApiSlice'; // Assuming you have a query for ads

const VerticalSlider = ({ activeIndex, setActiveIndex }) => {
    const { email } = useAuth();

    const {
        data: users,
        isLoading: usersLoading,
        isSuccess: usersSuccess,
        isError: usersError,
        error: usersErrorMessage,
    } = useGetUsersQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    // Fetch ads data
    const {
        data: ads,
        isLoading: adsLoading,
        isSuccess: adsSuccess,
        isError: adsError,
        error: adsErrorMessage,
    } = useGetAdvertisersQuery(); // Fetching real ads

    const adsArray = ads ? Object.values(ads.entities) : []

    console.log(adsArray)

    const carouselRef = useRef(null);
    const [transitionTime, setTransitionTime] = useState(500); // Set initial transition time
    const [isMuted, setIsMuted] = useState(true); // Start with muted by default

    const beforeChange = (index) => {
        setActiveIndex(index); // Update activeIndex on change
        console.log("Active index updated:", index);
    };

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.selectedItem = activeIndex;
        }
    }, [activeIndex]);

    if (usersLoading || adsLoading) return <p>Loading.....</p>;
    if (usersError || adsError) return <p>Failed to load data.</p>;

    if (usersSuccess && adsSuccess) {
        const userArray = users ? Object.values(users.entities) : [];
        const loggedUser = userArray.filter((user) => user.email === email);

        let following;

        if (!loggedUser[0]?.following?.length) {
            following = ['659bdaf4eb19ce78a32c2779', '65764b36325b4bd94dcac732', '659bdb1deb19ce78a32c277c'];
        } else {
            following = loggedUser[0].following;
        }

        following = userArray
            .filter(
                (user) =>
                    user.roles &&
                    user.roles.includes('provider') &&
                    user.email !== email &&
                    user?.stories?.length &&
                    !user.deactivated
            )
            .map((user) => user._id);

        // Ensure ads start after the first three stories
        return (
            <div className={VerticalStoriesCSS['slider-container']}>
                <Carousel
                    selectedItem={activeIndex}
                    onChange={beforeChange}
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                    emulateTouch={true}
                    transitionTime={transitionTime}
                    interval={null}
                    infiniteLoop={true}
                    axis={'vertical'}
                    ref={carouselRef}
                >
                    {following.flatMap((follower, index) => {
                        const components = [
                            <UserStories
                                key={`story-${index}`}
                                follower={follower}
                                loggedUser={loggedUser}
                                isActive={index === activeIndex}
                                isNext={index - 1 === activeIndex || activeIndex === following.length - 1}
                                isPrevious={index + 1 === activeIndex || (index === following.length - 1 && activeIndex === 0)}
                                isMuted={isMuted}
                                setIsMuted={setIsMuted}
                            />,
                        ];

                        // Insert an ad after every 3 creators, starting from the third item
                        if ((index + 1) % 3 === 0 && index >= 2) {
                            const adIndex = Math.floor((index + 1) / 3) - 1; // Cycle through ads
                            const ad = adsArray[adIndex % adsArray.length]; // Select the ad
                            components.push(<Ad key={`ad-${adIndex}`} ad={ad} />);
                        }

                        return components;
                    })}
                </Carousel>
            </div>
        );
    }

    return <p>Loading.....</p>;
};

export default VerticalSlider;
