import { useGetAdsByManagerQuery } from '../../features/advertiser/advertiserApiSlice';
import AdsCSS from './Ads.module.css';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import addImage from '../../assets/img/gallery-placeholder.jpg';
import { useNavigate } from 'react-router-dom';
import RemoveAd from './RemoveAd';
import { useEffect, useState } from 'react';

const AdsList = ({ loggedUser }) => {
    const navigate = useNavigate();
    const [chosenAd, setChosenAd] = useState('');
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const { data: ads, isLoading, error } = useGetAdsByManagerQuery(loggedUser?._id);

    const [adsList, setAdsList] = useState([]);

    useEffect(() => {
        setAdsList(ads);
    }, [ads]);

    if (isLoading) return <p>Loading ads...</p>;
    if (error) return <p>Failed to load ads.</p>;

    return (
        <div className="page-container">
            <h2>Your Ads:</h2>
            <div className={AdsCSS['ads-list']}>
                {adsList?.length > 0 ? (
                    adsList.map(ad => (
                        <div key={ad._id} className={AdsCSS['single-ad']}>
                            <div className={AdsCSS['left-column']}>
                                <h4>{ad.name}</h4>
                                <p>Impressions: {ad.impressions}</p>
                                <p>Likes: {ad.likes}</p>
                            </div>
                            <div className={AdsCSS['right-column']}>
                                <div>
                                    <p>{Math.ceil((new Date(ad.expiresAt) - new Date()) / (1000 * 60 * 60 * 24))} days left</p>
                                    <div className={AdsCSS['ad-adtions']}>
                                        <CreateOutlinedIcon onClick={() => navigate(`/dash/ads/edit/${ad._id}`)} />
                                        <DeleteIcon onClick={() => {
                                            setChosenAd(ad._id);
                                            setIsConfirmationOpen(true);
                                        }} />
                                    </div>
                                </div>
                                <div>
                                    {ad.mediaURL && ad.mediaType && ad.mediaType.startsWith('video') ? (
                                        <video controls autoPlay loop>
                                            <source src={ad?.mediaURL} type={ad?.mediaType} />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <img src={ad.mediaURL || addImage} alt={`${ad.name} creative`} />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No ads found for your account. Start creating your first ad!</p>
                )}
                <button className={AdsCSS['submit-button']} onClick={() => navigate('/dash/ads/new-ad')}>
                    Add New Ad
                </button>
            </div>
            <RemoveAd isConfirmationOpen={isConfirmationOpen} closeModal={() => setIsConfirmationOpen(false)} adId={chosenAd} />
        </div>
    );
};

export default AdsList;
