import React from 'react';

const Ad = ({ ad }) => {
    const handleClick = () => {
        window.location.href = 'https://www.google.com';
    };

    return (
        <div className="ad-container">
            <video loop autoPlay muted playsInline>
                <source src={ad?.mediaURL} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <button className="ad-button" onClick={handleClick}>Visit site</button>
        </div>
    );
};

export default Ad;
