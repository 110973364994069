import React, { useState } from 'react';
import AdsCSS from './Ads.module.css';
import addImage from '../../assets/img/gallery-placeholder.jpg';
import { useNavigate } from 'react-router-dom';
import { useAddNewAdvertiserMutation } from '../../features/advertiser/advertiserApiSlice';
import { useUploadPostMutation } from "../../features/story/imageApiSLice"; // Adjust the import based on your directory structure

const CreateNewAd = ({ loggedUser }) => {
    const navigate = useNavigate();
    const [adName, setAdName] = useState('');
    const [finalURL, setFinalURL] = useState('');
    const [duration, setDuration] = useState('7 days');
    const [mediaType, setMediaType] = useState('image');
    const [mediaURL, setMediaURL] = useState(addImage); // Default placeholder
    const [selectedFile, setSelectedFile] = useState(null);

    const [uploadImageToS3, { isSuccess: isUploadSuccess, isLoading: isUploadLoading, isError: isUploadError, error: uploadingError }] = useUploadPostMutation();
    const [addNewAdvertiser] = useAddNewAdvertiserMutation();

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setMediaType(file.type.startsWith('video') ? 'video' : 'image');
            setMediaURL(URL.createObjectURL(file)); // Preview URL for the uploaded file
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Ensure all required fields are filled
        if (!adName || !finalURL || !mediaType || !loggedUser || !loggedUser._id) {
            console.error("Missing required fields");
            return;
        }

        let uploadedFileURL = mediaURL;  // Default to current mediaURL if no file is selected
        let blurredFileURL = '';  // Default empty blurred URL

        // If a new file is selected, handle the upload
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('email', loggedUser.email);
            formData.append('userId', loggedUser._id);
            formData.append('description', 'ad');

            try {
                // Upload the image to S3
                const result = await uploadImageToS3(formData).unwrap();
                uploadedFileURL = result.fileUrl; // Get the URL for the uploaded image
            } catch (error) {
                console.error('Error uploading file:', error);
                return;
            }
        }

        // Prepare the ad data
        const newAd = {
            managerId: loggedUser._id,        // Manager's ID (loggedUser should be passed correctly)
            name: adName,                      // Ad name
            finalURL: finalURL,                // The final URL the ad links to
            mediaType: mediaType,              // Media type (image or video)
            mediaURL: uploadedFileURL,         // URL of the uploaded media
            expiresAt: new Date(Date.now() + getDurationInMs(duration)),  // Expiry date
        };

        try {
            // Create the new ad by passing the newAd object to the mutation
            await addNewAdvertiser(newAd).unwrap();
            navigate('/dash/ads');  // Navigate to the ads dashboard after creation
        } catch (error) {
            console.error('Failed to create the ad:', error);
        }
    };

    const getDurationInMs = (duration) => {
        switch (duration) {
            case '7 days':
                return 7 * 24 * 60 * 60 * 1000;
            case '15 days':
                return 15 * 24 * 60 * 60 * 1000;
            case '30 days':
                return 30 * 24 * 60 * 60 * 1000;
            default:
                return 0;
        }
    };

    return (
        <div className="page-container">
            <h2>Create new Ads:</h2>
            <form className={AdsCSS['create-ad']} onSubmit={handleSubmit}>
                <div className={AdsCSS['first-row']}>
                    <div>
                        <button className={`${duration === '7 days' ? AdsCSS['selected-duration'] : ''}`} type="button" onClick={() => setDuration('7 days')}>
                            7 days <span>100 CHF</span>
                        </button>
                        <button className={`${duration === '15 days' ? AdsCSS['selected-duration'] : ''}`} type="button" onClick={() => setDuration('15 days')}>
                            15 days <span>200 CHF</span>
                        </button>
                        <button className={`${duration === '30 days' ? AdsCSS['selected-duration'] : ''}`} type="button" onClick={() => setDuration('30 days')}>
                            30 days <span>350 CHF</span>
                        </button>
                    </div>
                    <div className={AdsCSS['image-section']}>
                            <>
                                <p>Add your Creative</p>
                                <p>
                                    Video or image <br/>
                                    Format: 1920x1080px<br/>
                                    Video: 5 - 15 sec
                                </p>
                            </>
                        {mediaType === 'image' ? (
                            <img src={mediaURL} alt="Ad creative" className={AdsCSS['creative-image']}/>
                        ) : (
                            <video controls autoPlay loop>
                                <source src={mediaURL} type={selectedFile.type} />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        <input
                            type="file"
                            accept="video/*, image/*"
                            onChange={handleFileUpload}
                            className={AdsCSS['file-input']}
                        />
                    </div>
                </div>
                <div className={AdsCSS['second-row']}>
                    <input
                        type="text"
                        placeholder="Ad Name"
                        value={adName}
                        onChange={(e) => setAdName(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Final URL"
                        value={finalURL}
                        onChange={(e) => setFinalURL(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className={AdsCSS['submit-button']}>Add New Ad</button>
            </form>
        </div>
    );
};

export default CreateNewAd;
